import React from "react";
import axios from "axios";
import { ChatRequestPayloadContext } from "../contexts/ChatRequestPayloadContext";
import { ProductListContext } from "../contexts/ProductListContext";
import { IconButton, Tooltip } from "@mui/material";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import './customTable.css'
const ActionProvider = ({
  createChatBotMessage,
  setState,
  children,
  ...rest
}) => {
  const { payload, updatePayload } = React.useContext(
    ChatRequestPayloadContext
  );
  const { setClearFilters } = React.useContext(ProductListContext);

  const { updateProducts, setLoading } = React.useContext(ProductListContext);

  async function handleBotReply(message) {
    const emptyReply = createChatBotMessage(null);
    setState((prev) => ({
      ...prev,
      messages: [...prev.messages, emptyReply],
    }));

    await axios
      .post(
        // `https://ai-retail-cpr-pasfr-test.azurewebsites.net/api/productsForSearch?new=true`
        `https://ai-retail-pandora-api.azurewebsites.net/v2/chatbot?query=${message}`,
        payload
      )
      .then((response) => {
        if (response?.data && response?.data?.reply) {
          const botMessage = createChatBotMessage(response?.data?.reply);
          console.log(botMessage)
          let products = response?.data?.suggestions;

          updatePayload(response?.data?.conversation_object);
          const suggestedProductIds =
            response?.data?.productId && response?.data?.productId.length > 0
              ? response?.data?.productId
              : [];
          if (products.length) {
            setLoading(true);
            // Apply suggested field
            const suggestedProducts = products.map((product) => {
              product["suggested"] =
                suggestedProductIds.indexOf(product.productId) > -1;
                
              return product;
            });
            // Sort products for suggested most
            products = suggestedProducts.sort((a, b) => {
              if (a.suggested && !b.suggested) {
                return -1;
              } else if (!a.suggested && b.suggested) {
                return 1;
              } else {
                return 0;
              }
            });
            setTimeout(() => {
              setLoading(false);
            }, 1000);
          }
          updateProducts(products);
          setState((prev) => {
            const prevMsgs = [...prev.messages];
            const emptyIndex = prevMsgs.findIndex(
              (item) => item.id === emptyReply.id
            );
            prevMsgs.splice(emptyIndex, 1, botMessage);
            return {
              ...prev,
              messages: prevMsgs,
            };
          });
        } else {
          console.log("Got empty data");
          throw new Error("Got empty data from server.");
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log("Got Error while getting chat reply", error);
        const botMessage = createChatBotMessage(
          "Sorry! Failed to get response."
        );
        setState((prev) => {
          const prevMsgs = [...prev.messages];
          const emptyIndex = prevMsgs.findIndex(
            (item) => item.id === emptyReply.id
          );
          prevMsgs.splice(emptyIndex, 1, botMessage);
          return {
            ...prev,
            messages: prevMsgs,
          };
        });
      });
  }

  function handleRest() {
    updatePayload && updatePayload([]);
    setClearFilters((prev) => !prev);
    const initialMsg = createChatBotMessage("Hello Steven !");
    setState((prev) => ({
      ...prev,
      messages: [initialMsg],
    }));
  }

  return (
    <div>
      {React.Children.map(children, (child) => {
        return React.cloneElement(child, {
          actions: {
            handleBotReply,
            handleRest,
          },
        });
      })}
      <Tooltip title="Reset conversation">
        <IconButton
          sx={{
            position: "fixed",
            right: "4px",
            bottom: "6px",
          }}
          onClick={(e) => handleRest()}
        >
          <RotateLeftIcon fontSize="medium" />
        </IconButton>
      </Tooltip>
    </div>
  );
};

export default ActionProvider;

